<template>
    <div :class="deviceType == 'pc'?'case-details':'case-details-m'" class="flex-column">
        <div class="banner flex-column">
            <h3>客户案例</h3>
            <h1>{{list[index].name}}</h1>
        </div>
        <div class="summary flex-column">
            <h3>方案概述</h3>
            <p>{{list[index].content}}</p>
        </div>
        <div class="summary flex-column">
            <h3>效果展示</h3>
            <div class="details-box flex-row">
                <img v-for="(item,ind) in list[index].imgList" alt="" :src="item" :key="'module1' + ind" class="details" :class="list[index].type == 1 ?'pc':''">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    name: "三合壹会员积分系统解决方案",
                    type: 0,
                    content: "三合壹积分会员互联网平台将会员与积分管理巧妙地融合在一起，专门针对各大电子商务网站对会员管理、会员权利管理的需求而开发。利用企业自媒体开展会员互动营销，实现企业价值传播，最终转化为商业价值。",
                    imgList: [
                        require("../assets/da9b59913840c4ee7bfd4d74c0e1225.jpg"),
                        require("../assets/bcb9175d36b11791df6365a681b5fb7.png"),
                        require("../assets/65432b8cf47d184c5631efa3af539cc.png")
                    ]
                },
                {
                    name: "伊米阳光商城小程序",
                    type: 0,
                    content: "随着新零售、移动互联网概念的提出，电商行业创新模式层出不穷。最后一公里社区服务也出现了新的发力点。针对新的概念我们提出了新的社区拼团移动电商解决方案。通过微信的便捷流量入口，动员社区宝妈等人员成为团长，成就定制式社区生活。",
                    imgList: [
                        require("../assets/20201112172211.png"),
                        require("../assets/20201112172223.png"),
                        require("../assets/20201112172231.png")
                    ]
                },
                {
                    name: "调味帮",
                    type: 0,
                    content: "电商行业随着直播电商、社交电商、分销电商、无人超市等新型概念提出。线下传统电商企业转型成为聚焦问题。社维思为帮助传统制造商成功转型提出新型解决方案。通过互联网技术让企业线下业务成功转型为线上线下同步发展业务，互相辅助，提高销售量，利润成倍增长。",
                    imgList: [
                        require("../assets/20201112172440.jpg"),
                        require("../assets/20201112172448.jpg"),
                        require("../assets/20201112172456.jpg")
                    ]
                },
                {
                    name: "可悦社交APP",
                    type: 0,
                    content: "可悦是一款专为同城男女打造的真实互动的熟人或半熟人交友娱乐平台。我们的目标是为用户提供安全、真实、友好、高效的互动交友环境。",
                    imgList: [
                        require("../assets/1af3f2cdc5bdcab652c431d1886258b.png"),
                        require("../assets/1e8ef4134ba56231d883f2900444b1f.png"),
                        require("../assets/bf0e1c1b742692bdabaf9f5422ea103.png")
                    ]
                },
                {
                    name: "飞创e家",
                    type: 0,
                    content: "飞创e家是社区+智慧生活的平台模式。主要是充分利用物联网、云计算、移动互联网等实现一种便捷的生活方式。",
                    imgList: [
                        require("../assets/50dfc64ff679fc6c1eab7d80f0017d2.jpg"),
                        require("../assets/15408fa6a0d5fe968ecb851cf35f721.jpg"),
                        require("../assets/f6a7790e7322ae2cb95037a8c50a24c.jpg")
                    ]
                },
                {
                    name: "滴家智行",
                    type: 0,
                    content: "为用户提供社区安防问题解决方案。满足客户定制化扩展需求，我们运用操作可行的IoT方案，定制化的智能管理功能，实时的信息交互，系统能够对设备可能存在或可能出现的故障进行预警，设备的品质控制，为社区提供安全的生活环境。",
                    imgList: [
                        require("../assets/20201112174335.png"),
                    ]
                },
                {
                    name: "丙星OA",
                    type: 0,
                    content: "建筑OA面向建筑公司使用的项目工具平台,该平台可为项目人员(安全质量员、施工员、资料员、材料员、技术负责人、现场商务、后勤员、现场财务员、项目经理、总经理)提供全方位的业务流程,查看项目开展情况.可以通过灵活配置,满足每个建筑工程项目的OA系统。s",
                    imgList: [
                        require("../assets/7d7c5462343cfd212d2a3230d5eda91.jpg"),
                        require("../assets/6434b95c79b10ec7ed0b9d0c138c4ac.jpg"),
                        require("../assets/677462b5694f3eca51d38a3d634c6b0.jpg")
                    ]
                },
                {
                    name: "美点充电小程序",
                    type: 0,
                    content: "致力于移动充电解决方案，为用户提供便捷及时的移动电源租借服务。美点在为用户提供优质充电体验的同时，也利用自身平台优势不断对商户进行精细化运营，为合作伙伴提供品牌宣传，产品曝光，口碑提升，引流拉新等综合解决方案。美点旨在让生活玩家玩转生活不断电，随时随地，即借即用。致力于在各场景中通过源源不断的能源和智能硬件网络，结合物联网大数据，以无时无刻的服务满足用户与商户的需求。",
                    imgList: [
                        require("../assets/20201112175648.png"),
                        require("../assets/20201112175654.png"),
                        require("../assets/20201112175700.png")
                    ]
                },
                {
                    name: "唯知教育",
                    type: 1,
                    content: "社维思教育培训解决方案覆盖了教育培训的整个生命周期，帮助企业解决学生培训过程的管理难题。方案通过在线课程、培训班级、培训项目等多种学习方式，满足企业对学员的培养。在学习理念上，以学员为中心，问题为导向，结果为目标。在内容上采用互动式资源，学习内容娱乐化编排，从而提高学员的学习积极性，最终为企业提高培训管理水平与教学质量。",
                    imgList: [
                        require('../assets/case17.png')
                    ]
                },
                // {
                //     imgList: [require('../assets/case02.png')],
                //     type: 1,
                //     content: "飞创e家是社区+智慧生活的平台模式。主要是充分利用物联网、云计算、移动互联网等实现一种便捷的生活方式。",
                //     name: '智慧社区服务大数据系统',
                // },
                {
                    imgList: [require('../assets/case03.png')],
                    type: 1,
                    content: "三合壹积分会员互联网平台将会员与积分管理巧妙地融合在一起，专门针对各大电子商务网站对会员管理、会员权利管理的需求而开发。利用企业自媒体开展会员互动营销，实现企业价值传播，最终转化为商业价值。",
                    name: '三合壹会员积分系统',
                },
                {
                    imgList: [require('../assets/case04.png')],
                    type: 1,
                    content: "企业项目管理，一直是企业最头痛的难题。邮件项目管理系统是一个敏捷管理项目的定制式管理平台。针对企业多公司多项目的灵活管理手段，简单易上手操作。智能的项目邮件预警，让执行更有效。",
                    name: '邮件管理系统',
                },
                {
                    imgList: [require('../assets/case10.png')],
                    type: 1,
                    content: "结合各类教育场景，快速实现教育信息化，智能化。支持APP，H5全面覆盖，跨平台满足不同教育需求场下的使用条件，保持多渠道下数据同步，实现业务闭环。根据客户需求，模块化功能封装，方便快捷实现在线培训考试教育，节省开发时间和开发成本。",
                    name: '消防线上考试培训',
                },
                {
                    imgList: [require('../assets/case11.png')],
                    type: 1,
                    content: "致力于智慧社区智慧物业管理解决方案。方案运用大数据、物联网、云计算等先进科学技术，帮助物业企业解决社区管理和服务难题。",
                    name: '熊猫e家物业管理系统',
                },
                // {
                //     imgList: [require('../assets/case12.png')],
                //     type: 1,
                //     content: "飞创e家是社区+智慧生活的平台模式。主要是充分利用物联网、云计算、移动互联网等实现一种便捷的生活方式。",
                //     name: '智慧公安系统',
                // },
                // {
                //     imgList: [require('../assets/case13.png')],
                //     type: 1,
                //     content: "飞创e家是社区+智慧生活的平台模式。主要是充分利用物联网、云计算、移动互联网等实现一种便捷的生活方式。",
                //     name: '智慧城市管理数据分析',
                // },
                // {
                //     imgList: [require('../assets/case14.png')],
                //     type: 1,
                //     content: "飞创e家是社区+智慧生活的平台模式。主要是充分利用物联网、云计算、移动互联网等实现一种便捷的生活方式。",
                //     name: '园区运行态势数据展示平台',
                // },
            ],
            index: 0
        }
    },
    created() {
        this.index = this.$route.query.index
    }
}
</script>

<style lang="less" scoped>
.case-details {
    padding: 80px 0;
    .banner {
        width: 100vw;
        min-width: 600px;
        height: 400px;
        position: relative;
        overflow: hidden;
        background: url('../assets/case_banner.png') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: flex-start;
        h1 {
            font-size: 48px;
            margin-left: 120px;
        }
        h3 {
            font-size: 36px;
            margin-left: 120px;
            margin-bottom: 24px;
        }
    }
    .summary {
        width: 1440px;
        align-items: flex-start;
        margin: 60px 0;
        // padding: 0 120px;
        h3 {
            font-size: 36px;
            margin: 60px 0;
            font-weight: bolder;
        }
        p {
            font-size: 28px;
            text-align: left;
            text-indent: 56px;
        }
        .details-box {
            width: 1440px;
            padding: 64px;
            flex-wrap: wrap;
            justify-content: center;
            box-shadow: 2px 4px 4px 4px #cccccc;
            .details {
                width: 320px;
                height: 640px;
                margin: 0 32px;
                object-position: top;
                object-fit: cover;
            }
            .pc {
                width: 1000px;
                height: auto;
            }
        }
    }
}
.case-details-m {
    width: 100%;
    padding: 60px 0;
    h1 {
        font-size: 18px;
        margin: 20px 0;
    }
    h3 {
        font-size: 24px;
        margin: 16px 0;
        font-weight: bolder;
    }
    p {
        font-size: 14px;
        text-align: start;
        text-indent: 28px;
        padding: 0 16px;
    }
    .banner {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        background: url('../assets/case_banner.png') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: center;
    }
    .details-box {
        width: 100%;
        padding: 0 16px;
        flex-wrap: wrap;
        justify-content: center;
        .details {
            width: 100%;
            // height: 640px;
            margin: 32px 0;
            object-position: top;
            object-fit: cover;
            box-shadow: 2px 4px 4px 4px #cccccc;
        }
        .pc {
            width: 100%;
            height: auto;
        }
    }
}
</style>